import React from 'react'
import './EquipeSection.scss';
import Fade from 'react-reveal/Fade';

class EquipeSection extends React.Component {
    static defaultProps = {
        title: '',
        subtitle: '',
        image: ''
    };


    render() {
        const {title, subtitle, image} = this.props;

        return (
            <div className="EuipeSection">
                <div className="mt-8 p-8 container text-center">
                    <Fade>
                    {title && <h2 className="EquipeSection--Title">{title}</h2>}
                    {subtitle && <h3 className="EquipeSection--Subtitle mb-6">{subtitle}</h3>}
                    <hr></hr>
                    </Fade>
                    <Fade>
                    <div className="EquipeSection--Team--Container mt-8">
                        <img src={image} className="m-auto" alt="équipe de chirurgiens dentistes"></img>
                        <a href="/equipe"><button className="Button mt-6">Notre équipe</button></a>
                    </div>
                    </Fade>
                </div>
            </div>
        )
    }
}

export default EquipeSection
